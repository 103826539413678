
import { computed, defineComponent } from "vue";
import { useCoordinateStore } from "@/stores/coordinate";
import { useContentStore } from "@/stores/content";

export default defineComponent({
  setup() {
    const coordinateStrore = useCoordinateStore();
    const contentStore = useContentStore();
    const nums = computed(() => {
      return coordinateStrore.range(
        coordinateStrore.startY,
        coordinateStrore.endY,
        coordinateStrore.step
      );
    });
    return {
      contentStore,
      coordinateStrore,
      nums
    };
  }
});
