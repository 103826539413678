import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5be8b522"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["d", "stroke-width"]
const _hoisted_3 = ["d"]
const _hoisted_4 = ["x", "y"]
const _hoisted_5 = ["cx", "cy", "r", "stroke-width"]
const _hoisted_6 = ["cx", "cy"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.channel.channelDef.length > 1)
    ? (_openBlock(), _createElementBlock("g", _hoisted_1, [
        _createElementVNode("path", {
          d: _ctx.pathDef,
          stroke: "inherit",
          fill: "none",
          "stroke-width": _ctx.channel.properties.channelWidth.value,
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }, null, 8, _hoisted_2),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([...Array(_ctx.channel.channelDef.length - 1).keys()], (i) => {
          return (_openBlock(), _createElementBlock("g", { key: i }, [
            _createElementVNode("path", {
              d: `M${_ctx.channel.channelDef[i].position[0]} ${
          _ctx.channel.channelDef[i].position[1]
        } L${_ctx.channel.channelDef[i + 1].position[0]} ${
          _ctx.channel.channelDef[i + 1].position[1]
        }`,
              stroke: "white",
              fill: "none",
              "stroke-width": "20",
              "stroke-dasharray": "50,50",
              "stroke-linejoin": "round",
              "marker-start": "url(#arrowWhite)",
              "marker-end": "url(#arrowWhite)",
              opacity: "0.7"
            }, null, 8, _hoisted_3),
            _createElementVNode("text", {
              x: 
          (_ctx.channel.channelDef[i].position[0] +
            _ctx.channel.channelDef[i + 1].position[0]) /
          2
        ,
              y: 
          (_ctx.channel.channelDef[i].position[1] +
            _ctx.channel.channelDef[i + 1].position[1]) /
          2
        ,
              class: "channelSegnemtLength"
            }, _toDisplayString(_ctx.channelSegInput.segLength(
            _ctx.channel.channelDef[i],
            _ctx.channel.channelDef[i + 1]
          )) + " µm", 9, _hoisted_4)
          ]))
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.channel.channelDef, (vertex, index) => {
          return (_openBlock(), _createElementBlock("circle", {
            key: index,
            cx: vertex.position[0],
            cy: vertex.position[1],
            r: _ctx.controlStore.editingCircleRadius,
            fill: "white",
            "stroke-width": _ctx.controlStore.editingStrokeWidth
          }, null, 8, _hoisted_5))
        }), 128)),
        (_ctx.channelSegInput.lengthLocked)
          ? (_openBlock(), _createElementBlock("circle", {
              key: 0,
              cx: _ctx.coordinateStore.globalCoords[0],
              cy: _ctx.coordinateStore.globalCoords[1],
              r: "10000",
              fill: "transparent",
              stroke: "transparent",
              onMousemove: _cache[0] || (_cache[0] = ($event: any) => (_ctx.channelSegInput.updateChannelSegDirection($event.shiftKey)))
            }, null, 40, _hoisted_6))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}