import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["d", "stroke-width", "fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("path", {
    d: _ctx.pathDef,
    stroke: "inherit",
    "stroke-width": _ctx.strokeWidth,
    "stroke-linejoin": "round",
    fill: _ctx.fill
  }, null, 8, _hoisted_1))
}