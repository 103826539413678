
  import { useContentStore } from "@/stores/content";
  import { useControlStore } from "@/stores/control";
  import { useCoordinateStore } from "@/stores/coordinate";
  import { defineComponent } from "vue";
  import SvgElementHighlightVue from "./SvgElementHighlight.vue";

  export default defineComponent({
    components: {
      SvgElementHighlightVue,
    },
    setup() {
      const controlStore = useControlStore();
      const contentStore = useContentStore();
      const coordinateStore = useCoordinateStore();
      const mouseDownOnSelected = (shift: boolean, id: string) => {
        if (shift) {
          contentStore.removeFromSelected(id);
          if (contentStore.crtGroupId.length === 1) {
            contentStore.selectElement(contentStore.crtGroupId[0]);
            controlStore.setSelectionState(2);
            contentStore.resetCrtGroup();
          }
        } else {
          controlStore.startMovingGroup();
        }
      };
      return {
        contentStore,
        controlStore,
        coordinateStore,
        mouseDownOnSelected,
      };
    },
  });
