
import { useContentStore } from "@/stores/content";
import { defineComponent } from "vue";
import PartListButtonVue from "./PartListButton.vue";
export default defineComponent({
  components: {
    PartListButtonVue
  },
  setup() {
    const contentStore = useContentStore();
    return { contentStore };
  }
});
