import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["cx", "cy"]
const _hoisted_2 = ["x1", "y1", "x2", "y2", "stroke-width", "onMousemove", "onMouseenter"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("g", {
    onMouseenter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.controlStore.enableBridgePlacement())),
    onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onMouseLeave()))
  }, [
    (_ctx.coordinateStore.snapping)
      ? (_openBlock(), _createElementBlock("circle", {
          key: 0,
          cx: _ctx.coordinateStore.roundedGlobalCoords[0],
          cy: _ctx.coordinateStore.roundedGlobalCoords[1],
          r: "200",
          fill: "cyan",
          opacity: "0.7"
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contentStore.channelSegmentsForBridgePlacement, (line, idx) => {
      return (_openBlock(), _createElementBlock("line", {
        key: idx,
        x1: line.start.position[0],
        y1: line.start.position[1],
        x2: line.end.position[0],
        y2: line.end.position[1],
        stroke: "transparent",
        "stroke-width": line.width,
        onMousemove: ($event: any) => (_ctx.mouseMoveOnSegment(line)),
        onMouseup: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.mouseClickOnSegment()), ["stop"])),
        onMouseenter: ($event: any) => (_ctx.mouseEnterSegment(line))
      }, null, 40, _hoisted_2))
    }), 128))
  ], 32))
}