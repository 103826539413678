import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d5cc5636"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["transform"]
const _hoisted_2 = ["x", "y"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("g", {
    transform: _ctx.coordinateStrore.windowTranslate,
    class: "ruler"
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nums, (num) => {
      return (_openBlock(), _createElementBlock("text", {
        key: num,
        x: _ctx.coordinateStrore.globalToWindow(num),
        y: 
        _ctx.coordinateStrore.globalToWindow(-_ctx.coordinateStrore.globalTranslateY) + 50
      ,
        fill: "grey",
        "font-size": "small",
        "text-anchor": "middle"
      }, _toDisplayString(num), 9, _hoisted_2))
    }), 128))
  ], 8, _hoisted_1))
}