<template>
  <g fill="grey" stroke="#333333">
    <path d="M2.5 4 l9 -2.5 l5 7.5 l-5 7.5 l-9 -2.5 z" fill="none" />
    <circle cx="2.5" cy="4" r="1" />
    <circle cx="11.5" cy="1.5" r="1" />
    <circle cx="16.5" cy="9" r="1" />
    <circle cx="11.5" cy="16.5" r="1" />
    <circle cx="2.5" cy="14" r="1" />
  </g>
</template>
