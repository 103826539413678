import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["cx", "cy", "r", "stroke-width", "fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("circle", {
    cx: _ctx.element.x,
    cy: _ctx.element.y,
    r: _ctx.element.properties.radius.value,
    stroke: "inherit",
    "stroke-width": _ctx.strokeWidth,
    fill: _ctx.fill
  }, null, 8, _hoisted_1))
}