
import { computed, defineComponent } from "vue";
import { useControlStore } from "@/stores/control";
import { useContentStore } from "../stores/content";
import { Channel } from "@/library/channel";
import { ChannelPoint } from "@/library/other";
export default defineComponent({
  setup() {
    const controlStore = useControlStore();
    const contentStore = useContentStore();
    const canBeRounded = computed(() =>
      contentStore.crtLayerIds?.reduce((pre, id) => {
        const c = contentStore.getElement(id);
        if (c instanceof Channel && c.display) {
          const end = c.channelDef.length - 1;
          return pre.concat(c.channelDef.slice(1, end));
        } else return pre;
      }, Array<ChannelPoint>())
    );
    return {
      canBeRounded,
      controlStore,
      contentStore
    };
  }
});
