import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["cx", "cy", "r", "stroke-width", "onClick"]
const _hoisted_2 = ["cx", "cy", "r", "stroke-width", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("g", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.canBeRounded, (point, index) => {
      return (_openBlock(), _createElementBlock("circle", {
        key: index,
        cx: point.position[0],
        cy: point.position[1],
        r: _ctx.controlStore.editingCircleRadius,
        fill: "white",
        "stroke-width": _ctx.controlStore.editingStrokeWidth,
        onClick: ($event: any) => (_ctx.controlStore.addSelectedRounedCorner(point)),
        style: {"cursor":"pointer"}
      }, null, 8, _hoisted_1))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.controlStore.selectedChannelPoints, (point, index) => {
      return (_openBlock(), _createElementBlock("circle", {
        key: index,
        cx: point.position[0],
        cy: point.position[1],
        r: _ctx.controlStore.editingCircleRadius,
        fill: "gold",
        "stroke-width": _ctx.controlStore.editingStrokeWidth,
        onClick: ($event: any) => (_ctx.controlStore.removeSelectedRoundedCorner(point)),
        style: {"cursor":"pointer"}
      }, null, 8, _hoisted_2))
    }), 128))
  ]))
}