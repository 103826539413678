import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["cx", "cy"]
const _hoisted_2 = ["cx", "cy", "onMouseenter", "onMouseup"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("g", {
    stroke: "none",
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hover = false))
  }, [
    (_ctx.hover)
      ? (_openBlock(), _createElementBlock("circle", {
          key: 0,
          cx: _ctx.hoverPort[0],
          cy: _ctx.hoverPort[1],
          r: "200",
          fill: "cyan",
          opacity: "0.7"
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.portsOnCrtLevel, (port, index) => {
      return (_openBlock(), _createElementBlock("circle", {
        key: index,
        cx: port.position[0],
        cy: port.position[1],
        r: "400",
        fill: "transparent",
        onMouseenter: ($event: any) => (_ctx.setHoverPort(port.position)),
        onMousemove: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
        onMouseup: _withModifiers(($event: any) => (_ctx.bindChannelPort(port)), ["left","stop"])
      }, null, 40, _hoisted_2))
    }), 128))
  ], 32))
}