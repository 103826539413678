import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgLevelConnectionVue = _resolveComponent("SvgLevelConnectionVue")!

  return (_openBlock(), _createElementBlock("g", {
    class: _normalizeClass({ pointer: _ctx.controlStore.mode === _ctx.Mode.Select })
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contentStore.levelConnectionIds, (id, idx) => {
      return (_openBlock(), _createBlock(_component_SvgLevelConnectionVue, {
        class: "crtLayerElm",
        key: idx,
        element: _ctx.contentStore.getElement(id),
        onMousedown: _withModifiers(($event: any) => (
        _ctx.controlStore.mouseDownOnElement(id, $event.shiftKey || $event.ctrlKey)
      ), ["stop"])
      }, null, 8, ["element", "onMousedown"]))
    }), 128))
  ], 2))
}