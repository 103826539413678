<template>
  <path
    fill="#bbbbbb"
    stroke="#333333"
    d="M7.5
     18 v-8 l-4 -7.5 h 11  l -4 7.5 v8"
  />

  <!-- <g >
        <path d="M9 1H8V9.5H9V1Z" />
        <path d="M16 9H1V10H16V9Z" />
        <path d="M14 12H3V13H14V12Z" />
        <path d="M12 15H5V16H12V15Z" />
    </g> -->
</template>
