
import { useCoordinateStore } from "@/stores/coordinate";
import { useContentStore } from "@/stores/content";
import SvgElement from "./SvgElement.vue";
import { defineComponent } from "vue";
import SvgChannelDraw from "./SvgChannelDraw.vue";
import { useControlStore } from "@/stores/control";
import SvgCursor from "./SvgCursor.vue";
import ChannelSegmentsForBridgePlacementVue from "./ChannelSegmentsForBridgePlacement.vue";
import SvgEditElement from "./SvgEditElement.vue";
import SvgChannelPortsVue from "./SvgChannelPorts.vue";
import SvgChannelPointsRoundedVue from "./SvgChannelPointsRounded.vue";
import SvgLevelConnectionAllVue from "./SvgLevelConnectionAll.vue";
import SvgEditGroupVue from "./SvgEditGroup.vue";
import SvgPolygonDrawVue from "./SvgPolygonDraw.vue";
import { useChannelSegInputStore } from "@/stores/channelSegmentInput";
import {
  Mode,
  SelectionState,
  MouseOperation,
  ContentUnitGeneral
} from "@/library/other";

export default defineComponent({
  components: {
    SvgElement,
    SvgChannelDraw,
    SvgCursor,
    ChannelSegmentsForBridgePlacementVue,
    SvgEditElement,
    SvgChannelPortsVue,
    SvgChannelPointsRoundedVue,
    SvgLevelConnectionAllVue,
    SvgEditGroupVue,
    SvgPolygonDrawVue
  },
  props: {
    isActive: Boolean,
    idx: Number
  },
  setup() {
    const coordinateStore = useCoordinateStore();
    const contentStore = useContentStore();
    const controlStore = useControlStore();
    const channelSegInput = useChannelSegInputStore();
    return {
      coordinateStore,
      channelSegInput,
      contentStore,
      controlStore,
      Mode,
      SelectionState,
      MouseOperation,
      ContentUnitGeneral
    };
  }
});
