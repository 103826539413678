
import { defineComponent } from "vue";
import Popper from "vue3-popper";
import { useCoordinateStore } from "@/stores/coordinate";
import { useControlStore } from "@/stores/control";
import LevelVue from "./Level.vue";
import { computed } from "@vue/reactivity";
import { Mode } from "@/library/other";
import PanelLevelVue from "./PanelLevel.vue";
import PopperWrapperGreen from "./PopperWrapperGreen.vue";
export default defineComponent({
  components: {
    LevelVue,
    Popper,
    PanelLevelVue,
    PopperWrapperGreen
  },
  setup() {
    const controlStore = useControlStore();
    const showPopper = computed(
      () =>
        controlStore.mode === Mode.InterLayer && controlStore.showLevelPopper
    );
    const coordinate = useCoordinateStore();
    return { showPopper, coordinate, controlStore };
  }
});
