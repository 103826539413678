import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["transform"]
const _hoisted_3 = {
  opacity: "0.3",
  id: "svgContent"
}
const _hoisted_4 = ["stroke", "fill", "id"]
const _hoisted_5 = ["stroke", "fill"]
const _hoisted_6 = ["cx", "cy"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgElement = _resolveComponent("SvgElement")!
  const _component_SvgLevelConnectionAllVue = _resolveComponent("SvgLevelConnectionAllVue")!
  const _component_SvgCursor = _resolveComponent("SvgCursor")!
  const _component_SvgChannelDraw = _resolveComponent("SvgChannelDraw")!
  const _component_SvgPolygonDrawVue = _resolveComponent("SvgPolygonDrawVue")!
  const _component_SvgEditElement = _resolveComponent("SvgEditElement")!
  const _component_SvgEditGroupVue = _resolveComponent("SvgEditGroupVue")!
  const _component_SvgChannelPortsVue = _resolveComponent("SvgChannelPortsVue")!
  const _component_ChannelSegmentsForBridgePlacementVue = _resolveComponent("ChannelSegmentsForBridgePlacementVue")!
  const _component_SvgChannelPointsRoundedVue = _resolveComponent("SvgChannelPointsRoundedVue")!

  return (_openBlock(), _createElementBlock("svg", {
    class: "svg_canvas",
    width: _ctx.coordinateStore.canvasSide,
    height: _ctx.coordinateStore.canvasSide,
    viewBox: "0 0 1000000 1000000",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("g", {
      transform: _ctx.coordinateStore.globalTransform
    }, [
      _createElementVNode("g", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Array.from(_ctx.contentStore.layers.values()), (layer, idx) => {
          return (_openBlock(), _createElementBlock("g", {
            key: idx,
            stroke: layer.color,
            fill: layer.color,
            id: layer.id
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contentStore.layers.get(layer.id)?.contentIds, (id) => {
              return (_openBlock(), _createBlock(_component_SvgElement, {
                element: (_ctx.contentStore.contentMap.get(id) as ContentUnitGeneral),
                key: id
              }, null, 8, ["element"]))
            }), 128))
          ], 8, _hoisted_4))
        }), 128))
      ]),
      _createElementVNode("g", {
        stroke: _ctx.contentStore.colorOfCrtLayer,
        fill: _ctx.contentStore.colorOfCrtLayer
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contentStore.crtLayerIds, (id) => {
          return (_openBlock(), _createBlock(_component_SvgElement, {
            element: (_ctx.contentStore.contentMap.get(id) as ContentUnitGeneral),
            class: _normalizeClass({ crtLayerElm: _ctx.controlStore.mode === _ctx.Mode.Select }),
            key: id,
            onMousedown: _withModifiers(($event: any) => (
            _ctx.controlStore.mouseDownOnElement(
              id,
              $event.shiftKey || $event.ctrlKey
            )
          ), ["left","stop"])
          }, null, 8, ["element", "class", "onMousedown"]))
        }), 128)),
        _createVNode(_component_SvgLevelConnectionAllVue),
        (
            _ctx.controlStore.mode === _ctx.Mode.Channel ||
            _ctx.controlStore.mode === _ctx.Mode.Polygon ||
            _ctx.controlStore.mode === _ctx.Mode.InletOutlet ||
            _ctx.controlStore.mode === _ctx.Mode.Bridge ||
            _ctx.controlStore.mode === _ctx.Mode.InterLayer ||
            _ctx.controlStore.mode === _ctx.Mode.Circle ||
            _ctx.controlStore.isPlacingModule
          )
          ? (_openBlock(), _createBlock(_component_SvgCursor, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.controlStore.isAddingChannel)
          ? (_openBlock(), _createBlock(_component_SvgChannelDraw, {
              key: 1,
              channel: _ctx.controlStore.crtChannel
            }, null, 8, ["channel"]))
          : _createCommentVNode("", true),
        (_ctx.controlStore.isAddingPolygon)
          ? (_openBlock(), _createBlock(_component_SvgPolygonDrawVue, {
              key: 2,
              polygon: _ctx.controlStore.crtPolygon
            }, null, 8, ["polygon"]))
          : _createCommentVNode("", true),
        (
            _ctx.controlStore.selectionState === _ctx.SelectionState.EditingdSingleElement
          )
          ? (_openBlock(), _createBlock(_component_SvgEditElement, {
              key: 3,
              element: _ctx.contentStore.crtElement
            }, null, 8, ["element"]))
          : _createCommentVNode("", true),
        (_ctx.controlStore.selectionState === _ctx.SelectionState.EditingGroup)
          ? (_openBlock(), _createBlock(_component_SvgEditGroupVue, { key: 4 }))
          : _createCommentVNode("", true),
        (
            _ctx.controlStore.mode === _ctx.Mode.Channel ||
            _ctx.controlStore.operation === _ctx.MouseOperation.MovingChannelVertex
          )
          ? (_openBlock(), _createBlock(_component_SvgChannelPortsVue, { key: 5 }))
          : _createCommentVNode("", true),
        (
            _ctx.controlStore.isEditingBridge ||
            (_ctx.controlStore.isAddingChannel && !_ctx.channelSegInput.lengthLocked) ||
            (_ctx.controlStore.selectionState ===
              _ctx.SelectionState.EditingdSingleElement &&
              _ctx.controlStore.isEditingChannelPoint)
          )
          ? (_openBlock(), _createBlock(_component_ChannelSegmentsForBridgePlacementVue, { key: 6 }))
          : _createCommentVNode("", true),
        (_ctx.controlStore.mode === _ctx.Mode.RoundedCorner)
          ? (_openBlock(), _createBlock(_component_SvgChannelPointsRoundedVue, { key: 7 }))
          : _createCommentVNode("", true),
        (_ctx.controlStore.isPanning)
          ? (_openBlock(), _createElementBlock("circle", {
              key: 8,
              style: {"cursor":"grabbing"},
              cx: _ctx.coordinateStore.globalCoords[0],
              cy: _ctx.coordinateStore.globalCoords[1],
              r: "10000",
              fill: "transparent",
              stroke: "none",
              onMousemove: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (
            _ctx.coordinateStore.panWithMovement($event.movementX, $event.movementY)
          ), ["stop"]))
            }, null, 40, _hoisted_6))
          : _createCommentVNode("", true)
      ], 8, _hoisted_5)
    ], 8, _hoisted_2)
  ], 8, _hoisted_1))
}