
import { useContentStore } from "@/stores/content";
import { useControlStore } from "@/stores/control";
import { defineComponent } from "vue";
import SvgLevelConnectionVue from "./SvgLevelConnection.vue";
import { Mode } from "@/library/other";
export default defineComponent({
  components: {
    SvgLevelConnectionVue
  },
  setup() {
    const contentStore = useContentStore();
    const controlStore = useControlStore();
    return {
      contentStore,
      controlStore,
      Mode
    };
  }
});
