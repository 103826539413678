
import { computed, defineComponent } from "vue";
import { useCoordinateStore } from "@/stores/coordinate";

export default defineComponent({
  setup() {
    const coordinateStrore = useCoordinateStore();
    const nums = computed(() =>
      coordinateStrore.range(
        coordinateStrore.startX,
        coordinateStrore.endX,
        coordinateStrore.step
      )
    );

    return {
      coordinateStrore,
      nums
    };
  }
});
