
import {
  runDRC,
  designRules,
  channelWidthMin,
  compDistMin,
  minPortRadius,
  minViaRadius
} from "@/library/utilities/designRuleCheck";
import { defineComponent } from "vue";
import PartListButtonVue from "./PartListButton.vue";
export default defineComponent({
  components: {
    PartListButtonVue
  },
  setup() {
    return {
      runDRC,
      designRules,
      channelWidthMin,
      compDistMin,
      minPortRadius,
      minViaRadius
    };
  }
});
