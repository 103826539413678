
import { Channel } from "@/library/channel";
import { useChannelSegInputStore } from "@/stores/channelSegmentInput";
import { useControlStore } from "@/stores/control";
import { useCoordinateStore } from "@/stores/coordinate";
import { computed, defineComponent } from "vue";
export default defineComponent({
  props: {
    channel: { type: Channel, required: true }
  },
  setup(props) {
    const controlStore = useControlStore();
    const channelSegInput = useChannelSegInputStore();
    const coordinateStore = useCoordinateStore();
    const pathDef = computed(() => {
      let d = `M${props.channel.channelDef[0].position[0]} ${props.channel.channelDef[0].position[1]}`;
      for (let i = 1; i < props.channel.channelDef.length; ++i) {
        d += ` L${props.channel.channelDef[i].position[0]} ${props.channel.channelDef[i].position[1]}`;
      }
      return d;
    });

    return { controlStore, channelSegInput, pathDef, coordinateStore };
  }
});
