<template>
  <g>
    <!-- <rect x="4.5" y="1.5" width="9" height="15" stroke="#333333" fill="none" /> -->
    <path
      d="M0.5 9 h2 v-8 a1.5 1.5 0 0 1 3 0 v16 a1.5 1.5 0 0 0 3 0 v-16 a1.5 1.5 0 0 1 3 0 v16 a1.5 1.5 0 0 0 3 0 v-8 h2"
      stroke="#333333"
      fill="none"
    />

    <!-- <path d="M1 4.5H4M1 7.5H4M1 10.5H4M1 13.5H4M14 4.5H17M14 7.5H17M14 10.5H17M14 13.5H17" stroke="#333333" /> -->
  </g>
</template>
